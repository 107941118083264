.pf-c-log-viewer__text {
  margin: 0px 10px;
}

.pf-c-log-viewer__index {
  text-align: center;
}

.pf-c-log-viewer__header {
  margin-bottom: 5px;
}

.log-search-input {
  input {
    padding: 6px 8px 6px 32px !important;
  }

  .pf-c-text-input-group__text::before {
    border: 0;
  }
}

.pf-c-button {
  padding: 6px 4px;
}

.pf-c-badge {
  background-color: #f0f0f0;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: none;
}
