.ticket-conversation {
  --send-bg: #1e7ea1;
  --send-color: white;
  --receive-bg: #e5e5ea;
  --receive-color: black;
  --page-background: white;
  overflow: scroll;

  .message {
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 25px;
    }
  }

  .customer-text {
    align-self: flex-end;
    text-align: right;
    margin-right: 10px !important;
  }

  .agent-text {
    align-self: flex-start;
    text-align: left;
    margin-left: 10px !important;
  }

  .customer-msg {
    color: var(--send-color);
    background: var(--send-bg);
    align-self: flex-end;

    &:before {
      right: -7px;
      width: 20px;
      background-color: var(--send-bg);
      border-bottom-left-radius: 16px 14px;
    }

    &:after {
      right: -26px;
      width: 26px;
      background-color: var(--page-background);
      border-bottom-left-radius: 10px;
    }
  }

  .agent-msg {
    background: var(--receive-bg);
    color: var(--receive-color);
    align-self: flex-start;

    &:before {
      left: -7px;
      width: 20px;
      background-color: var(--receive-bg);
      border-bottom-right-radius: 16px 14px;
    }

    &:after {
      left: -26px;
      width: 26px;
      background-color: var(--page-background);
      border-bottom-right-radius: 10px;
    }
  }

  .attachment {
    max-width: 70%;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 40px 10px 20px;
    border-radius: 5px;
    border: 1px solid #ebeff3;
  }

  .customer-file {
    align-self: flex-end;
  }

  .agent-file {
    align-self: flex-start;
  }
}

.toastui-editor-toolbar-icons.image {
  display: none;
}

.ticket-reply-editor {
  .placeholder {
    cursor: none;
    background: none;
    display: unset;
  }
}
