
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: none;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 0.3px solid #97979737;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: #fff;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: #fff;
    border-bottom: 0.3px solid #97979737;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: none;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid #E6E6E6;
    text-align: center;
    font-weight: 500;
}

.react-calendar-timeline .rct-sidebar {
    background: #fff;
    // opacity: 0.83;
    // border-left: 0.5px solid #CAD0FE;
    // border-right: 0.5px solid #CAD0FE;
    // border-bottom: 0.5px solid #CAD0FE;
    border: none;
    // box-sizing: border-box;
    // box-shadow: 1px 6px 15px rgba(197, 197, 197, 0.5);
    // box-shadow: 0px 3px 10px rgba(8, 8, 13, 0.25);
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    font-weight: 600;
    border-right: 1px solid #E6E6E6;
    padding-bottom: 10px;
}

.react-calendar-timeline .rct-header-root {
    background: none;
    border: none;
}

.react-calendar-timeline .rct-calendar-header {
    border: none;
    border-top-right-radius: 10px;
}

.timeline-side-header-title {
    // color: #11567F;
    background: #fff;
    // opacity: 0.83;
    // border-bottom: none;
    // border-left: 0.5px solid #CAD0FE;
    // border-right: 0.5px solid #CAD0FE;
    // border-top: 0.5px solid #CAD0FE;
    // box-sizing: border-box;
    // box-shadow: 1px 6px 15px rgba(197, 197, 197, 0.5);
    // box-shadow: 0px 3px 10px rgba(8, 8, 13, 0.25);
    border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    font-weight: 600;
}

.react-calendar-timeline .rct-dateHeader {
    border: none;
    background: #fff;
    color: #000;
}

.react-calendar-timeline .rct-dateHeader-primary {
    font-weight: 600;
}

.date-header {
    border-bottom: 0.5px solid #cad0fe;
}

.react-calendar-timeline .rct-outer {
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
}

.continuous-region {
    background: #e8f5ff !important;
    opacity: 0.8;
    border-radius: 5px;
    border: none !important;
    color: #11567f !important;
    margin: 3px 0px;
    height: 29px !important;
    mix-blend-mode: multiply;
}
.continuous-region-log {
    background: #F29132 !important;
    opacity: 0.8;
    border-radius: 5px;
    border: none !important;
    color: #F29132 !important;
    margin: 3px 0px;
    height: 29px !important;
    mix-blend-mode: multiply;
}

.continuous-backup {
    background: #11567f99 !important;
    margin: 4px 0px;
    height: 27px !important;
}

.scrolling-carousel {
    overflow-y: visible;
}

.scrolling-carousel>[data-arrow='left']>button {
    background: rgba(20, 20, 20, 0.1) url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat center;
    background-size: 10px;
    transform: rotateZ(180deg);
    height: 100%;
    width: 20px;
    opacity: 0.7;
    border: none;
    outline: none;
    position: absolute;
    left: 0;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.scrolling-carousel>[data-arrow='left']>button:hover {
    background-color: rgba(20, 20, 20, 0.2);
    opacity: 1;
}

.scrolling-carousel>[data-arrow='right']>button {
    background: rgba(20, 20, 20, 0.1) url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat center;
    background-size: 10px;
    height: 100%;
    width: 20px;
    opacity: 0.7;
    border: none;
    outline: none;
    position: absolute;
    right: 0;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.scrolling-carousel>[data-arrow='right']>button:hover {
    background-color: rgba(20, 20, 20, 0.2);
    opacity: 1;
}

.timeline {
    border-style: solid;
    border-width: 2px;
    border-color: #11567F;
    padding-bottom: 1rem;
    border-radius: 10px;
}