/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/29/2021     bakul.banthia         Created
 *
 */
.aws-st0 {
  fill: #252F3E;
}

.aws-st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FF9900;
}

.azure-svg {
  fill: #0089d6;
  fill-opacity: 1;
  stroke-width: 0.28222221
}

.sqlserver-st0 {
  fill: url(#SVGID_1_);
}

.sqlserver-st1 {
  fill: url(#SVGID_2_);
}

.sqlserver-st2 {
  fill: url(#SVGID_3_);
}

.sqlserver-st3 {
  fill: #231F1F;
}
